@import '../node_modules/tailwindcss/base.css';
@import '../node_modules/tailwindcss/components.css';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

.popIn {
  animation: popIn 0.2s ease-in-out;
}

.fadeIn {
  animation: fadeIn 0.2s ease-in-out;
}
.mainHeader {
  background-color: #d7e9f1;
  gap: 1rem;
}

.mainHeader > :first-child {
  width: 100%;
}

select > option > {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: red;
}

.spinner {
  border: 2px solid transparent;
  border-left: 2px solid rgb(255, 255, 255);
  border-top: 2px solid rgb(255, 255, 255);
}

.spinnerTwo {
  border: 2px solid transparent;
  border-left: 2px solid blue;
  border-top: 2px solid blue;
}

.progressCircle {
  transform: rotate(-90deg);
  position: relative;
}

.progressCircleText {
  position: absolute;
  top: 0;
  content: '';
  display: block;
  background-color: red;
  color: red;
  left: 0;
  z-index: 999;
}
